<template>
  <div class="">
  
      <b-sidebar id="chatgpt-sidebar" class="" sidebar-class="sidebar-lg chatgpt-sidebar" bg-variant="white" shadow no-header right v-model='this.$store.state.app.chatVisibility'>
  
          <template #default="{ hide }">
              <!-- Header -->
              <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                  <div class="">
                      <h4 class="mb-0 text-white chat-bot">
                          {{companyName}} AI Assistant
                      </h4>
                  </div>
  
                  <div class="d-flex">
  
                      <b-dropdown variant="link" no-caret toggle-class="p-0" right class="chat-menu">
  
                          <template #button-content>
                              <b-button variant="primary" class="btn-icon chat-menu-icon">
                                  <feather-icon icon="MenuIcon" size="16" />
                              </b-button>
                          </template>
  
                          <b-dropdown-item @click='IsLoggedIn=true' v-if='!isLogg'>
                              <feather-icon icon="ArrowRightIcon" size="16" />
                              <span class="align-middle ml-50">Sign In</span>
                          </b-dropdown-item>
  
                          <b-dropdown-item @click='sendEmail'>
                              <feather-icon icon="SendIcon" size="16" />
                              <span class="align-middle ml-50">Send Email</span>
                          </b-dropdown-item>
  
                          <!-- <b-dropdown-item @click='openChatHistory'>
                              <feather-icon icon="ClockIcon" size="16" />
                              <span class="align-middle ml-50">Chat History</span>
                          </b-dropdown-item> -->
  
                          <b-dropdown-item @click='signOut' v-if='isLogg'>
                              <feather-icon icon="ArrowLeftIcon" size="16" />
                              <span class="align-middle ml-50">Sign Out</span>
                          </b-dropdown-item>
  
                      </b-dropdown>
  
                      <b-button variant="primary" class="btn-icon chat-close-icon ml-75" @click="closeChat">
                          <feather-icon class="cursor-pointer" icon="XIcon" size="16" />
                      </b-button>
                  </div>
  
              </div>
  
              <!-- Body -->
              <div class="p-0">
  
                  <b-card class="chat-widget mb-0" no-body>
  
                      <section class="chat-app-window">
                          <!-- User Chat Area -->
                          <vue-perfect-scrollbar ref="refChatLogPS" :settings="scrollbar" class="user-chats scroll-area">
  
                              <div class='mb-4'>
                                  <p class='user-name mb-0' :style="{ marginLeft: '20px' }">
                                      Hello {{full_name}} 👋 Nice to meet you!
                                  </p>
                              </div>
  
                              <chat-log :chat-data="chatData" :profile-user-avatar="tenantProfile" :user-image='profileImageUrl' :fullName='full_name' :companyName='company_name' />
  
                          </vue-perfect-scrollbar>
  
                          <!-- Message Input -->
                          <div class="lt-stage" v-if='chatLoading'>
                              <p class="loading-typing"><span>{{companyName}}</span> Assistant is typing</p>
                          </div>
  
                          <div class="closed-session" v-if='history' @click='newChat'>
                              <p>New Chat</p>
                          </div>
  
                          <!-- Send Enquiry Floating Button -->
  
                          <div class="regenerate-button">
  
                              <b-button variant="light" size="sm" type="submit" class='regenerate-btn' @click='isEmail = true' v-if='isLogg'>
                                  <feather-icon icon="SendIcon" size="14" /> Email to us
                              </b-button>
  
                          </div>
  
                          <b-form class="chat-app-form" @submit.prevent="sendMessage" v-if='!history'>
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                  <b-form-input v-model="chatInputMessage" placeholder="Send a message" />
                              </b-input-group>
  
                              <div class="gpt-rfresh mr-50">
  
                                  <feather-icon :class="{'mic-on': !speaking}" icon="MicIcon" size="16" @mousedown="speechToText" @mouseup="speechToTextStop" />
                              </div>
  
                              <div class="gpt-rfresh mr-50">
                                  <feather-icon class="cursor-pointer" icon="RefreshCwIcon" size="16" @click='refreshChat' />
                              </div>
  
                              <b-button variant="secondary" type="submit" :disabled='checkSend'>
                                  <feather-icon icon="SendIcon" size="16" />
                              </b-button>
  
                          </b-form>
  
                      </section>
  
                      <!-- Chat Gpt History -->
                      <!-- <chat-gpt-history class="" @chatHistoryDetails="chatHistoryOfUser" :chatHistory='userChatLists' /> -->
  
                  </b-card>
  
              </div>
              <!-- </vue-perfect-scrollbar> -->
  
              <!-- Banner Overlay -->
              <div class="cg-banner" v-if='IsLoggedIn'>
                  <div class="cg-signin">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="mb-0">Please log in to get access to more personalized services.</p>
  
                      </div>
                      <div class="">
                          <b-button class="mr-1" variant="outline-light" @click='continueChat'>Continue As Guest</b-button>
                          <b-button variant="outline-light" v-b-toggle.cg-signin-collapse>Sign In</b-button>
                      </div>
  
                      <b-collapse id="cg-signin-collapse" class="mt-1">
                          <b-row>
                              <b-col cols="7" md="8">
                                  <b-form-group label="" label-for="">
                                      <b-form-input placeholder="Email Address" v-model='email_address' />
                                  </b-form-group>
                              </b-col>
                              <b-col cols="5" md="4" class="pl-0">
                                  <b-button variant="secondary" type="" @click='sendOTP'>
                                      {{send_otp}}
                                  </b-button>
                              </b-col>
                          </b-row>
  
                          <b-row v-if='isVerify'>
                              <b-col md="12">
                                  <b-form-group label="" label-for="">
                                      <b-form-input placeholder="Enter Name" v-model='user_name' />
                                  </b-form-group>
                              </b-col>
                          </b-row>
  
                          <b-row v-if='isVerify'>
                              <b-col md="12">
                                  <div class="">
                                      <label class="text-muted lead"> Enter the OTP</label>
                                  </div>
                              </b-col>
  
                              <b-col cols="2" md="2" class="pr-0 otp-inputs" v-for="(field, index) in inputFields" :key="index">
                                  <input :ref="'inputField' + index" @input="handleInput(index)" maxlength="1" class="form-control" v-model="inputFields[index]" />
                              </b-col>
  
                          </b-row>
  
                          <b-button class="mt-2" variant="secondary" type="" @click='verifyOTP' v-if='isVerify'>
                              Continue
                          </b-button>
  
                      </b-collapse>
  
                  </div>
              </div>
  
              <!-- Send E-mail Overlay -->
  
              <div class="cg-send-email-banner" v-if='isEmail'>
                  <div class="">
                      <p class="">Send us an email and one of our team member will get back to you soon.</p>
                  </div>
  
                  <b-row>
                      <b-col md="12" class="mb-75">
                          <label class="text-muted">Select Category / Topic</label>
                          <b-form-select v-model="category" :options="categoryList" />
                      </b-col>
  
                      <b-col md="12" class="mb-1">
                          <label class="text-muted">Enter your message</label>
                          <b-form-textarea placeholder="" rows="4" max-rows="14" class="" v-model='enquiry_message' />
                      </b-col>
  
                      <b-col md="12">
                          <b-form-file v-model="file" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                      </b-col>
  
                      <b-col md="12" class="mt-1">
                          <div class="d-flex align-items-center">
                              <b-button @click="cancelEmail" variant="outline-secondary" type="" class="mr-75">
                                  <feather-icon icon="XIcon" size="16" class="mr-25" />Cancel</b-button>
                              <b-button variant="secondary" type="" @click='insertUpdateSupportTickets'>
                                  <feather-icon icon="SendIcon" size="16" class="mr-25" />Email to us</b-button>
                          </div>
                      </b-col>
                  </b-row>
              </div>
  
          </template>
  
      </b-sidebar>
  
  </div>
  </template>
  
  <script>
  import {
      BLink,
      BFormRadioGroup,
      BFormGroup,
      BFormSelect,
      BFormTextarea,
      BFormFile,
      BFormCheckbox,
      BSidebar,
      BCard,
      BCardHeader,
      BBadge,
      VBToggle,
      BAlert,
      BDropdown,
      BDropdownItem,
      BDropdownDivider,
      BCollapse,
      BPopover,
      VBPopover,
      BCarousel,
      BCarouselSlide,
  } from 'bootstrap-vue'
  import {
      BEmbed
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import ChatLog from './ChatLog.vue'
  import ChatGptHistory from './ChatGptHistory.vue'
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
      components: {
          // BSV
          BCard,
          BCardHeader,
          BCarousel,
          BCarouselSlide,
          BLink,
          BPopover,
          VBPopover,
          BFormRadioGroup,
          BFormSelect,
          BFormTextarea,
          BFormFile,
          BFormCheckbox,
          BFormGroup,
          BEmbed,
          BSidebar,
          BAlert,
          BDropdown,
          BDropdownItem,
          BDropdownDivider,
          BCollapse,
  
          ChatLog,
          ChatGptHistory,
  
          // 3rd party
          vSelect,
          VuePerfectScrollbar,
          ToastificationContent,
          BBadge
  
      },
  
      directives: {
          'b-toggle': VBToggle,
      },
  
      data() {
          return {
  
              initializeChat: false,
              chatLoading: false,
  
              company_name: '',
              chatHistory: '',
              // chatID: 0,
              chatInputMessage: '',
              userChatLists: '',
              speechRecognition: new webkitSpeechRecognition(),
              speaking: true,
              chatData: [],
              user_id: '',
              history: false,
              IsLoggedIn: false,
              apiRequest: 0,
              email_address: '',
              user_name: '',
  
              login_id: '',
              isVerify: false,
              send_otp: 'Send OTP',
  
              inputFields: Array(4).fill(""),
              isContinue: true,
  
              checkSend: false,
              enquiry_message: '',
              search_results: [],
              filters: [],
              filter_query: {},
              filteredArray: '',
  
              category: null,
              file: null,
  
              categoryList: [{
                      text: "Enquiry",
                      value: 'Enquiry'
                  },
                  {
                      text: "Sales",
                      value: 'Sales'
                  },
                  {
                      text: "Support",
                      value: 'Support'
                  },
              ],
            isEmail: false,
            currentIndex: 0,
            lastEmailSentTime: 0, 
            emailTimeoutId: null,
          }
      },

      created() {
    //     window.addEventListener('beforeunload', this.handleBeforeUnload);
    // },
    // beforeDestroy() {
    //     window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
  
      setup() {
  
          // Perfect Scrollbar
          const scrollbar = {
              maxScrollbarLength: 60,
              wheelPropagation: false,
          }
  
          return {
              // Perfect Scrollbar 
              scrollbar,
          }
      },
  
      mounted() {
  
          this.psToBottom()
  
      },
      computed: {
          full_name() {
              const fullName = this.$store.state.app.fullName;
              return fullName && fullName.trim() !== '' ? fullName : 'there';
          },
          offset() {
              return -this.currentIndex * 100 + '%';
          },
          profileImageUrl() {
              return this.$store.state.app.profileImage
          },
          isLogg() {
              return this.$store.state.app.isLogged
          },
          isEnquire() {
             
              return this.$store.state.app.isEnquire
          },
          companyName() {
              return this.$store.state.app.companyName
          },
          tenantProfile() {
              return this.$store.state.app.tenantProfile
          }
  
      },
      watch: {
          isEnquire(newValue, oldValue) {
  
              if (newValue) {
                  this.chatData = []
  
                  this.fetchDataFromGPT(this.$store.state.app.searchEnquiry)
              }
  
          },
      },
  
      methods: {
        // handleBeforeUnload(event) {
            // console.log('handleBeforeUnload triggered');
            // Cancel the timeout to prevent it from sending the email again
            // clearTimeout(this.emailTimeoutId);

            // Send the email immediately if the user is closing the website
            // this.sendChatEmail(true); // Pass a flag to force sending the email
        // },
  
          handleInput(index) {
              if (index < this.inputFields.length - 1) {
                  this.$refs['inputField' + (index + 1)][0].focus();
              }
          },
          newChat() {
              this.chatData = []
        
              this.$store.commit("app/SET_CHAT_ID", 0);
              this.initializeChat = true;
              this.history = false
          },
  
          cancelEmail() {
              this.isEmail = false
              this.enquiry_message = ''
              this.category = null
              this.file = null
          },
          sendEmail() {
              if (this.isLogg == false) {
                  this.IsLoggedIn = true
                  return false;
              } else {
                  this.isEmail = true
              }
  
          },
          openChatHistory() {
              this.getChatHistoryBasedOnUserId()
              if (this.isLogg == false) {
                  this.IsLoggedIn = true
                  return false;
              } else {
                  this.$root.$emit('bv::toggle::collapse', 'sidebar-1')
              }
  
          },
  
          async insertUpdateSupportTickets() {
  
              if (this.category == "" || this.category == null) {
  
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "Please select category",
                          icon: 'AlertCircleIcon',
                          variant: 'warning'
                      },
                  })
                  return false;
              } else if (this.enquiry_message == "" || this.enquiry_message == null) {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "Please enter your message",
                          icon: 'AlertCircleIcon',
                          variant: 'warning'
                      },
                  })
                  return false;
              } else {
  
                  var myHeaders = new Headers();
                //   const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                  let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;                 
                //   myHeaders.append('x-api-key', api_key);
  
                  const formData = new FormData();
                  const userData = localStorage.getItem("userdetails")
                  if (userData == null || undefined) {
                      this.user_id = null
                  } else {
                      this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                  }
  
                  formData.append("tenant_id", tenant_id);
                  formData.append("user_id", this.user_id);
                  formData.append("question", this.enquiry_message);
                  formData.append("support_category", this.category);
                  formData.append("uploads[]", this.file);
                  formData.append("chat_id", this.$store.state.app.chatID);
                  var requestOptions = {
                      method: "POST",
                      headers: myHeaders,
                      body: formData,
                      redirect: "follow",
                  };
  
                  const rawResponse = await fetch(
  
                      process.env.VUE_APP_BASE_URL + "insertUpdateTicket",
  
                      requestOptions
                  );
  
                  const response = await rawResponse.json();
  
                  if (response.data[0].status == 1) {
  
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: "Thank you! We recieved your email and will contact you soon.",
                              icon: 'CheckIcon',
                              variant: 'success',
                          },
                      })
                      this.isEmail = false;
                      this.enquiry_message = ''
                      this.category = ''
              
                      this.$store.commit("app/SET_CHAT_ID", 0);
                  } else if (response.data[0].status == 0) {
  
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: "Oops, something went wrong, please try again.",
                              icon: 'AlertCircleIcon',
  
                              variant: 'danger'
                          },
                      })
                      this.isEmail = false;
                      this.enquiry_message = ''
                      this.category = ''
                
                      this.$store.commit("app/SET_CHAT_ID", 0);
                  }
              }
  
          },
          async verifyOTP() {
  
              const concatenatedNumber = this.inputFields.join("");
  
              if (this.user_name == "" || this.user_name == null) {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Please enter your name',
  
                          icon: 'AlertCircleIcon',
  
                          variant: 'danger'
                      },
                  })
                  return false;
              } else {
                  const myHeaders = new Headers();
                  let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
                //   const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                //   myHeaders.append('x-api-key', api_key);
                  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                  const urlencoded = new URLSearchParams();
                  urlencoded.append("email", this.email_address);
                  urlencoded.append("tenant_id", tenant_id);
                  urlencoded.append("user_otp", concatenatedNumber);
                  urlencoded.append("user_name", this.user_name);
                  urlencoded.append("user_id", this.login_id);
                  const requestOptions = {
                      method: "POST",
                      headers: myHeaders,
                      body: urlencoded,
                      redirect: "follow",
                  };
  
                  const rawResponse = await fetch(
                      process.env.VUE_APP_BASE_URL + "verifyOTPAndSignIn",
                      requestOptions
                  );
  
                  const response = await rawResponse.json();
                  if (response.status == true) {
  
                      this.isVerify = false
                      this.IsLoggedIn = false
                      this.$store.commit("app/SET_USER_DETAILS", JSON.stringify(response.data[0]));
                      this.$store.commit("app/SET_USER", true);
                      this.$store.commit("app/SET_USER_FEATURE", response.data[0].is_user_features_required);
  
                      this.$store.commit("app/SET_FULL_NAME", response.data[0].full_name);
                      this.user_name = ''
                      this.inputFields = Array(4).fill("")
                      this.email_address = ''
                      this.send_otp = 'Send OTP'
  
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: `Welcome ${response.data[0].full_name}. You have signed in successfully.`,
  
                              icon: 'CheckIcon',
  
                              variant: 'success'
                          },
                      })
                      return false;
                  } else {
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: response.message,
  
                              icon: 'AlertCircleIcon',
  
                              variant: 'danger'
                          },
                      })
                      return false;
                  }
              }
          },
          continueChat() {
              this.IsLoggedIn = false
              this.isContinue = false
          },
          signOut() {
              localStorage.removeItem('userdetails');
              this.$store.commit("app/SET_FULL_NAME", "");
              this.$store.commit("app/SET_PROFILE_URL", "");
              this.history = false
              this.$root.$emit('bv::toggle::collapse', "chatgpt-sidebar")
              this.chatData = []
              this.initializeChat = false;
              this.$store.commit("app/SET_USER", false);
              this.isEmail = false;
              this.enquiry_message = ''
              this.category = ''
  
              this.send_otp = 'Send OTP'
  
              this.isVerify = false
              this.IsLoggedIn = false
  
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'You have successfully signed out.',
                      icon: 'CheckIcon',
                      variant: 'success'
                  },
              })
              return false;
          },
          async sendOTP() {
              if (this.email_address == '' || this.email_address == null) {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Please enter your email address.',
  
                          icon: 'AlertCircleIcon',
  
                          variant: 'danger'
                      },
                  })
                  return false;
              } else {
                  this.send_otp = 'Sending'
  
                  const myHeaders = new Headers();
                  let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
                //   const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                //   myHeaders.append('x-api-key', api_key);
                  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
                  const urlencoded = new URLSearchParams();
                  urlencoded.append("email", this.email_address);
                  urlencoded.append("tenant_id", tenant_id);
                  const requestOptions = {
                      method: "POST",
                      headers: myHeaders,
                      body: urlencoded,
                      redirect: "follow",
                  };
  
                  const rawResponse = await fetch(
                      process.env.VUE_APP_BASE_URL + "UserSignIn",
                      requestOptions
                  );
  
                  const response = await rawResponse.json();
  
                  if (response.status == true) {
                      this.isVerify = true
                      this.user_name = response.data[0].full_name
                      this.login_id = response.data[0].user_id
                      this.send_otp = 'Resend'
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: 'An OTP sent to your email address. Please enter below to continue.',
  
                              icon: 'CheckIcon',
  
                              variant: 'success'
                          },
                      })
                      return false;
                  }
  
              }
          },
  
          //Populating chat history details to chat
        //   chatHistoryOfUser(response) {
        //       this.$store.commit("app/SET_CHAT_ID", response.data[0].chat_id);
        //       // this.chatID = response.data[0].chat_id
        //       this.history = true;
        //       const chats = response.data[0].chat_history_json.chat.chat
        //       this.chatData = []
        //       this.chatData.push({
        //           message: 'Sure! Here is the chat history you looking for',
        //           time: new Date(),
        //           senderID: 1,
        //           history: 1
  
        //       })
        //       this.initializeChat = true;
        //       chats.forEach((chat) => {
        //           this.chatData.push({
        //               message: chat.question,
        //               time: new Date(),
        //               senderID: 2,
        //               liked: chat.likeDislike == "like" ? true : false,
        //               disliked: chat.likeDislike == 'dislike' ? true : false
        //           })
        //           this.chatData.push({
        //               message: chat.message,
        //               time: new Date(),
        //               senderID: 1,
        //               liked: chat.likeDislike == "like" ? true : false,
        //               disliked: chat.likeDislike == 'dislike' ? true : false
        //           })
        //       });
        //   },
  
          //Fetching user's chat history//
        //   async getChatHistoryBasedOnUserId() {
        //       const userData = localStorage.getItem("userdetails")
        //       const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
        //       if (userData == null || undefined) {
        //           this.user_id = null
        //       } else {
        //           this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
        //       }
  
        //       const myHeaders = new Headers();
        //       myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        //       myHeaders.append('x-api-key', api_key);
        //       var urlencoded = new URLSearchParams();
        //       let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
        //       urlencoded.append("tenant_id", tenant_id);
        //       urlencoded.append("user_id", this.user_id);
  
        //       const requestOptions = {
        //           method: "POST",
        //           headers: myHeaders,
        //           body: urlencoded,
        //           redirect: "follow"
        //       };
        //       const rawResponse = await fetch(
  
        //           process.env.VUE_APP_BASE_URL + "getChatHistoryBasedOnUserId",
        //           requestOptions
        //       );
        //       const response = await rawResponse.json();
  
        //       this.userChatLists = response.data
  
        //   },
  
          closeChat() {
              
              this.$store.commit("app/SET_ENQUIRY", false);
              this.$store.commit("app/SET_PRODUCT_ID", "");
              this.$store.commit("app/SET_QUERY_TYPE", "");
              this.history = false;
              this.$root.$emit('bv::toggle::collapse', "chatgpt-sidebar");
              this.$store.commit("app/SET_INITIALIZE_CHAT", false);
              this.$store.commit("app/SET_CHAT_ID", 0);
            //   this.chatData = []
              this.initializeChat = false;
              this.IsLoggedIn = false;
              this.user_name = '';
                  this.email_address = '';
              this.chatInputMessage = '';
              this.send_otp = 'Send OTP';
  
              this.inputFields = Array(4).fill("");
              this.isEmail = false;
              this.enquiry_message = '';
              this.category = null;
              this.file = null;
              this.isVerify = false;
              this.chatLoading = false;
              this.checkSend = false;

             // Clear any existing timeout to avoid multiple emails
             clearTimeout(this.emailTimeoutId);

             // Set a timeout to send the email after 2 minutes
             this.emailTimeoutId = setTimeout(() => {
                // console.log('Timeout for sending email triggered');
            this.sendChatEmail();
             }, 1 * 60 * 1000);
  
          },
    // async sendChatEmail(force = false) {
    // const currentTime = Date.now();
    // const twoMinutes = 1 * 60 * 1000; // Corrected to 2 minutes

    // Check if the last email was sent more than 2 minutes ago, or if forced to send
    // if (!force && currentTime - this.lastEmailSentTime < twoMinutes) {
    //     console.log('Email not sent: Less than 2 minutes since last email');
    //     return; // Do not send the email if less than 2 minutes have passed and not forced
    // }

    // const myHeaders = new Headers();
    // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
    // let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;                 
    // myHeaders.append('x-api-key', api_key);
    // const question = `Chat with ${this.$store.state.app.full_name}`;
    // const support_category = "Chat";
    // const tenantData = JSON.parse(localStorage.getItem("domain_Data"));
    // const userData = localStorage.getItem("userdetails");
    // const user_id = userData ? JSON.parse(userData).user_id : 0;
    // const chat_id = this.$store.state.app.chatID;
    // Check if file is not null or undefined before appending
    // const file = this.file || null;

    // Create FormData object
    // const formData = new FormData();
    // formData.append("tenant_id", tenant_id);
    // formData.append("user_id", user_id);
    // formData.append("question", question);
    // formData.append("support_category", support_category);            
    // if (file) {
    //     formData.append("uploads[]", file);
    // }
    // formData.append("chat_id", chat_id);

//     console.log('Sending email with formData:', {
//         tenant_id,
//         user_id,
//         question,
//         support_category,
//         chat_id
//     });

//     const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: formData,
//         redirect: "follow",
//     };

//     try {
//         const response = await fetch(process.env.VUE_APP_BASE_URL + "insertUpdateTicket", requestOptions);
//         const result = await response.json();

//         if (result.data && result.data[0].status === 1) {
//             console.log('Email sent successfully');
//             this.showToast('Chat history email sent successfully!', true);
//             this.lastEmailSentTime = currentTime;
//         } else {
//             console.log('Failed to send email:', result);
//             this.showToast('Failed to send chat history email.', false);
//         }
//     } catch (error) {
//         console.error('Error sending chat history email:', error);
//         this.showToast('Error sending chat history email.', false);
//     }
// },

          refreshChat() {
              this.chatData = []
              this.chatLoading = false
              this.checkSend = false
              this.$store.commit("app/SET_CHAT_ID", 0);
          },
  
          //fetching chatGPT results//
          async fetchDataFromGPT(val) {
         
              this.chatInputMessage = ''
              this.chatLoading = true
              this.checkSend = true
              const userData = localStorage.getItem("userdetails")
              if (userData == null || undefined) {
                  this.user_id = null
              } else {
                  this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
              }
  
              const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
              const chatMessage = {
                  senderID: 1,
                  message: '',
                  time: new Date(),
                  liked: false,
                  disliked: false
              };
              var myHeaders = new Headers();
              let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            //   myHeaders.append('x-api-key', api_key);
              myHeaders.append('Accept', "text/event-stream");
              var urlencoded = new URLSearchParams();
              urlencoded.append("tenant_id", tenant_id);
              urlencoded.append("user_id", this.user_id);
              urlencoded.append("question", val);
              urlencoded.append("chat_id", this.$store.state.app.chatID);
              urlencoded.append("question_type", 'product');
              urlencoded.append("product_id", this.$store.state.app.productId);
              urlencoded.append("query_type", this.$store.state.app.query_type);
  
              var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: "follow",
                  // mode:'no-cors'
  
              };
              const rawResponse = await fetch(
  
                  process.env.VUE_APP_BASE_URL + "imageChat",
                  requestOptions
              );
  
              // const reader = rawResponse.body.getReader();
              let currentMessage = {
                  senderID: 1,
                  message: '', // Initialize an empty message
                  time: new Date(),
                  liked: false,
                  disliked: false,
              };
              let chatlength = this.chatData.length;
              this.chatData.push(currentMessage)
              for (const reader = rawResponse.body.getReader();;) {
                  const {
                      value,
                      done
                  } = await reader.read();
  
                  if (done) {
                      break;
                  }
  
                  setTimeout(() => {
                      var chunk = new TextDecoder().decode(value);
                      const regex = /{{~(\d+)~}}/;
                      const match = chunk.match(regex);
                      if (match) {
                          // this.chatID = parseInt(match[1]);
                          this.$store.commit("app/SET_CHAT_ID", parseInt(match[1]));
  
                          this.$store.commit("app/SET_LIKE_DISLIKE_ID", this.$store.state.app.chatID);
  
                      }
                      let messageval = this.chatData[chatlength].message;
                      const modifiedSentence = chunk.replace(/{{~\d+~}}/g, '');
                      this.chatData[chatlength].message = messageval + modifiedSentence;
                  }, 100);
  
                  // return false;
  
              }
              this.chatLoading = false
              this.checkSend = false
  
          },
  
          //Sending Prompt to chatGPT//
          async sendMessage() {
              const userData = localStorage.getItem("userdetails")
              this.apiRequest++
  
              if (this.chatInputMessage == '' || this.chatInputMessage == null) {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Please enter your message',
  
                          icon: 'AlertCircleIcon',
  
                          variant: 'warning'
                      },
                  })
                  return false;
              } else if (userData == null && this.apiRequest == 3 && this.isContinue) {
                  this.IsLoggedIn = true
  
                  this.chatLoading = false
                  return false
  
              } else {
  
                  this.chatData.push({
                      senderID: 2,
                      message: this.chatInputMessage,
                      time: new Date(),
                      liked: false,
                      disliked: false
                  })
                  setTimeout(() => {
                    this.fetchDataFromGPT(this.chatInputMessage);
                }, 50);
              }
          },
  
          //Using the webkitSpeechRecognition API to listen to the user's voice
          speechToText() {
  
              let final_transcript = "";
  
              this.speechRecognition.continuous = true;
              this.speechRecognition.interimResults = true;
              this.speechRecognition.start();
              this.speaking = false;
              // Event listener for the "result" event
              this.speechRecognition.onresult = (event) => {
                  const transcript = event.results[event.results.length - 1][0].transcript;
                  this.chatInputMessage = transcript;
              };
  
          },
  
   async speechToTextStop() {
    // Stop speech recognition
    this.speechRecognition.stop();
    this.speaking = true;

    // Listen for the 'end' event to ensure the recognition has fully stopped
    this.speechRecognition.onend = async () => {
      await this.sendMessage();
    };
  },
  
          psToBottom() {
              const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
              scrollEl.scrollTop = scrollEl.scrollHeight
          },
  
      }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import "@core/scss/base/pages/app-chat-list.scss";
  </style><style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';
  
  .slider {
      display: flex;
      overflow: hidden;
      margin: 20px 0;
  }
  
  .slides {
      display: flex;
      transition: transform 0.5s ease-in-out;
  }
  
  .slide {
      min-width: 33.33%;
      box-sizing: border-box;
      margin-right: 2px;
  }
  
  .slide img {
      width: 100%;
      height: auto;
      margin-right: 5px;
  }
  
  .controls {
      margin-top: 10px;
      margin-left: 155px;
  }
  
  .control-button {
      background-color: #333;
      color: #fff;
      padding: 10px;
      margin: 0 5px;
      cursor: pointer;
      border: none;
  }
  
  .btn {
      cursor: pointer;
      color: white;
  }
  
  .btn:hover {
      color: black;
  }
  
  /* Define the animation */
  @keyframes fadeIn {
      0% {
          opacity: 0;
      }
  
      100% {
          opacity: 1;
      }
  
  }
  
  /* Apply the animation to the element */
  .fade-in-element {
      animation: fadeIn 2s ease-in-out;
      /* Adjust the duration and easing as per your preference */
  }
  
  .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
  }
  
  /* Style for each item */
  .item {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: center;
      margin-top: 5px;
      margin-left: 3px;
  }
  
  /* Style for the images */
  .item img {
      max-width: 100%;
      height: auto;
  }
  
  .customizer-section {
      padding: 1.5rem;
      border-bottom: 1px solid #ebe9f1;
  
      .dark-layout & {
          border-color: $theme-dark-border-color;
      }
  
      #skin-radio-group ::v-deep {
          .custom-control-inline {
              margin-right: 0.7rem;
          }
      }
  
      .form-group {
          margin-bottom: 1.5rem;
          ;
  
          &:last-of-type {
              margin-bottom: 0;
          }
  
          ::v-deep legend {
              font-weight: 500;
          }
      }
  }
  
  .mark-active {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, .5);
  }
  
  .mic-on {
      color: red;
  }
  
  .closed-session {
      text-align: center;
      color: white;
      background: black;
      cursor: pointer
  }
  
  .ps-customizer-area {
      height: calc(100% - 83px)
  }
  
  // .chat-bot{
  //     position:relative;
  //     left:75px;
  // }
  .user-name {
      position: absolute;
      margin-left: 52px;
      left: -100%;
      /* Start the string off the left side of the container */
      top: 5%;
      transform: translateY(-50%);
      white-space: nowrap;
      /* Prevent the string from wrapping to the next line */
      animation: appearString 1s forwards;
      color: #fff;
      /* Animation properties */
  }
  
  .cg-alert {
      padding: 0.5rem 0.5rem !important;
      font-size: 11px !important;
      font-weight: 600 !important;
      margin-bottom: 0;
      z-index: 1;
  
  }
  
  .cg-alert.alert-warning {
      // background: rgba(255, 159, 67) !important;
      background-color: #dc3545 !important;
      color: #fff !important;
      border: 1px solid #dc3545 !important;
  }
  
  // .cg-alert.alert-dismissible .close{
  //     padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  // }
  
  @keyframes appearString {
      0% {
          left: -100%;
          /* Starting position */
          opacity: 0;
          /* Starting opacity */
      }
  
      100% {
          left: 0;
          /* Ending position (center of the container) */
          opacity: 1;
          /* Ending opacity */
      }
  }
  
  .product-categories {
      cursor: pointer;
  }
  
  .otp-input-container {
      display: flex;
      align-items: center;
      gap: 8px;
      /* Adjust the gap between input fields */
  }
  
  .otp-input-field {
      width: 24px;
      /* Adjust the width of the input fields */
      height: 24px;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
  }
  </style>