import {
  $themeBreakpoints
} from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    fetching_fields: '',
    shallShowOverlay: false,
    cartLength: 0,
    cartData: [],
    companyName: '',
    profileImage: '',
    fullName: '',
    userEmail: '',
    userID: '',
    userRole: '',
    likeDislikeID: 0,
    chatID: 0,
    prompt_template: "",
    isLogged: false,
    isEnquire: 0,
    isSideFilter:false,
    filterArrays:'',
    isVerified:false,
    searchEnquiry: '',
    productList: [],
    searchQuery: '',
    chatVisibility: false,
    productId: null,
    query_type: '',
    featuredProduct: false,
    banner: [],
    featured_product_list: [],
    featured_images: [],
    tenantProfile: '',
    imageUrl: '',
    fieldVectors: '',
    vectorQueries: [],
    collections: [],
    filter_fields: [],
    userCountry:'',
    filters: [],
    filter_query: [],
    index_name: '',
    search: '',
    user_featured: 0,
    feedImages: []
  },
  getters: {
    banner: state => state.banner,
    getFeedImages: (state) => state.feedImages,
    featured_images: state => state.featured_images,
    currentBreakPoint: state => {
      const {
        windowWidth
      } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, value) {
      state.windowWidth = value
    },
    SET_IS_VERIFIED(state,value){
          state.isVerified=value
    },
    TOGGLE_OVERLAY(state, value) {
      state.shallShowOverlay = value !== undefined ? value : !state.shallShowOverlay
    },
    SET_TENANT_DOMAIN_DATA(state, value) {
      localStorage.setItem("domain_Data", value)
    },

    SET_COMPANY_NAME(state, value) {
      state.companyName = value
    },
    SET_INITIALIZE_CHAT(state, value) {
      state.chatVisibility = value
    },
  
    SET_QUERY_TYPE(state, value) {
      state.query_type = value
    },
    SET_APP_PROFILE(state, value) {
      state.tenantProfile = value
    },

    SET_STATUS(state, value) {
      if (value == "") {
        state.searchQuery = "";
      } else {
        if (state.searchQuery === '') {

          state.searchQuery = value;
        } else {

          state.searchQuery += ` ${value}`;
        }
      }

    },
    SET_USER_ID(state, value) {
      state.userID = value
    },
    SET_USER_EMAIL(state, value) {
      state.userEmail = value
    },
    SET_REMOVE_ITEMS() {
      // Remove userData from localStorage
      localStorage.removeItem('userdetails')
      localStorage.removeItem('userName')
      localStorage.removeItem('user_profile')
      localStorage.removeItem('tenant_Data')
      // localStorage.removeItem('domain_Data')

      localStorage.removeItem('name')
      localStorage.removeItem('profile_url')
    },

    SET_COMMA(state, val) {
      state.search += val
    },


    // UPDATE_SEARCH(state, value) {
    //   state.search = state.search.replace(value, '');

    // },

    SET_FEED_IMAGES(state, images) {
      state.feedImages = images;
    },
    SET_IMAGE_URL(state, val) {
      state.imageUrl = val
    },
   
    SET_USER(state, value) {
      state.isLogged = value
    },
    SET_ENQUIRY(state, value) {
      state.isEnquire = value
    },
   

    SET_PROFILE_URL(state, value) {
      state.profileImage = value
    },
    SET_FULL_NAME(state, value) {
      state.fullName = value
    },
    SET_USER_COUNTRY(state, value) {
      state.userCountry = value
    },
    SET_USER_ROLE(state, value) {
      state.userRole = value
    },
    SET_LIKE_DISLIKE_ID(state, value) {
      state.likeDislikeID = value

    },
    SET_CHAT_ID(state, value) {
      state.chatID = value

    },
    SET_USER_DETAILS(state, val) {
      localStorage.setItem("userdetails", val)

    },
    SET_BANNER(state, val) {
      state.banner = val
    },
    SET_FEATURED_IMAGES(state, val) {
      state.featured_images = val;
    }
  },
  actions: {
    async getBannerDetails({ commit }) {
      try {
        const tenantData = JSON.parse(localStorage.getItem("domain_Data"));
        if (!tenantData) {
          throw new Error("No tenant data found in localStorage");
        }
    
        const { tenant_id, api_key } = tenantData;
        const myHeaders = new Headers();
        myHeaders.append('x-api-key', api_key);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
        const urlencoded = new URLSearchParams();
        urlencoded.append("tenant_id", tenant_id);
        urlencoded.append("user_type", "public_user");
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };
    
        const rawResponse = await fetch(`${process.env.VUE_APP_BASE_URL}banners`, requestOptions);
    
        if (!rawResponse.ok) {
          const errorText = await rawResponse.text();
          throw new Error(`HTTP error! Status: ${rawResponse.status}, Response: ${errorText}`);
        }
    
        const response = await rawResponse.json();
        
        // Check if the response contains the expected data
        if (!response || !response.data) {
          throw new Error(`Unexpected response format: ${JSON.stringify(response)}`);
        }
    
        commit('SET_BANNER', response.data);
      } catch (error) {
        console.error('Error fetching banner:', error.message);
        console.error('Error details:', error);
      }
    },

    async fetchFeedImages({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}getFeed`);
        const data = await response.json();
        commit('SET_FEED_IMAGES', data.images);
      } catch (error) {
        console.error('Error fetching feed images:', error);
      }
    },
    
    async fetchFeaturedItems({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}getFeaturedItems`);
        const data = await response.json();
        if (Array.isArray(data.images)) {
          localStorage.setItem('featured_images', JSON.stringify(data.images));
          commit('SET_FEATURED_IMAGES', data.images);
        } else {
          console.error('Expected an array of featured images, but got:', data.images);
        }
      } catch (error) {
        console.error('Error fetching featured items:', error);
      }
    },
    loadFeaturedImagesFromStorage({ commit }) {
      const images = JSON.parse(localStorage.getItem('featured_images') || '[]');
      commit('SET_FEATURED_IMAGES', images);

    }
  },
}