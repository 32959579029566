<template>
<div class="chats">

    <div v-for="(chats, index) in chatData" :key="chats[index]" class="chat" :class="{'chat-left mb-1': chats.senderID !== 1}">

        <div class="chat-avatar">
            <b-avatar size="36" class="avatar-border-2 box-shadow-1" variant="transparent" :src="chats.senderID === 1? profileUserAvatar: userImage" :text="avatarText(fullName)" />
        </div>

        <div class="chat-body">

            <div class="chat-content">

                <span v-html="convertToLink(chats.message)" style="white-space: pre-line;"></span>

                <div class="like-dislike">
                    <div class="like-btn" @click='likeChatResponse(index,chats)'>
                        <feather-icon size="14" icon="ThumbsUpIcon" color='green' v-if="chats.liked==true" />
                        <feather-icon size="14" icon="ThumbsUpIcon" color='white' v-else />

                    </div>
                    <div class="like-btn" @click='dislikeChatResponse(index,chats)'>
                        <feather-icon size="14" icon="ThumbsDownIcon" color='red' v-if="chats.disliked==true" />
                        <feather-icon size="14" icon="ThumbsDownIcon" color='white' v-else />

                    </div>

                </div>

            </div> 

        </div>

    </div>

</div>
</template>

<script>
import {
    computed
} from '@vue/composition-api'
import {
    BAvatar
} from 'bootstrap-vue'
import {
    avatarText
} from '@core/utils/filter'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {

    BPopover,
    VBPopover,

} from 'bootstrap-vue'
export default {

    components: {
        BAvatar,
        ToastificationContent,
        BPopover,
        VBPopover,
    },
    data() {
        return {
            liked: false,
            disliked: false,
            avatarText,
            question: ''

        }
    },
    // setup() {
    //     return {
    //         avatarText,
    //     }
    // },

    props: {
        chatData: {
            type: Array,
            required: true,
        },

        profileUserAvatar: {
            type: String,
            required: true,
        },
        userImage: {
            // type: String,
            required: true
        },
        fullName: {
            type: String
        },
        searchResults: {
            type: Array
        },
        
    },

    methods: {

        // openLink(event) {
        //     const clickedUrl = event.target.textContent;

        //     window.open(clickedUrl, '_blank')
        // },
        // fetchProductURL(link) {
        //     window.open(link, '_blank')
        // },
        // handleClickEvent(event) {
        //     if (event.target.classList.contains("link-url")) {
        //         this.openLink(event);
        //     }
        // },

        //Positive feedback for chat response
        likeChatResponse(value, val) {

            const chats = this.chatData
            const index = value;

            if (index == 0) {

                this.question = ""
            } else {
                const question_index = value - 1
                this.question = chats[question_index].message
            }

            val.liked = !val.liked
            val.disliked = false;
            this.likeDislikeResponse('like', this.question)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Thank you for your feedback',
                    variant: 'success',
                    icon: 'CheckIcon'
                },
            })

        },

        convertToLink(inputString) {
            
            const lines = inputString.split('\n');
            const formattedLines = lines.map(line => {
                if (line.startsWith('- **')) {
                    const productName = line.substring(4, line.length - 3);
                  
                    // return `<li><b>${productName}</b></li>`;
                    return `.<b>${productName}</b>`;
                }

                if (line.startsWith('- **')) {
                    return `<li>${line.substring(4)}</li>`;
                }

                if (line.includes('[]')) {
                    const linkText = line.substring(line.indexOf('[') + 1, line.indexOf(']'));
                    const link = line.substring(line.indexOf('(') + 1, line.indexOf(')'));
                    return `<a href="${link}">${linkText}</a>`;
                }
                return line;
            });

            const formattedText = formattedLines.join('\n');
            const urlPattern = /\b(https?:\/\/[^\s()]+\S)\b/g;
            const coloredString = formattedText.replace(urlPattern, (url) => {
                const cleanedURL = url.replace(/^[(]/, '').replace(/[)]$/, '');
                return `<a href=${cleanedURL} class="link-url" target='_blank'>Visit</a>`;
            });
            return coloredString;

        },

        //Negative feedback for chat response
        dislikeChatResponse(value, val) {
            const chats = this.chatData
            const index = value;

            if (index == 0) {

                this.question = chats[0].message
            } else {
                const question_index = value - 1
                this.question = chats[question_index].message
            }

            val.disliked = !val.disliked
            val.liked = false;
            this.likeDislikeResponse('dislike', this.question)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Thank you for your feedback',
                    variant: 'danger',
                    icon: 'CheckIcon'
                },
            })
        },

        //Positive Feedback for chat response
        async likeDislikeResponse(action, qs) {

            // const api_key = localStorage.getItem('api_key')
            var myHeaders = new Headers();
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            // myHeaders.append('x-api-key', api_key);
            var urlencoded = new URLSearchParams();
            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("like_dislike", action);

            urlencoded.append("question", qs);
            urlencoded.append("chat_id", this.$store.state.app.likeDislikeID, );

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "insertUpdateLikeAnswerInChats",
                requestOptions
            );
            const response = await rawResponse.json();
            console.log(response)

        },
    },

}
</script>

<style>
.like {
    color: green;
}

.dislike {
    color: red;
}

.link-url {
    color: #007bff;
    /* Change the color to your desired color */
    text-decoration: underline;
    /* Add underline or other styling as desired */
    cursor: pointer;
    /* Change cursor style to indicate it's a link */
}

.product-lists-gpt {
    cursor: pointer;
}
</style>
